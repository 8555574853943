import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "links" }, [
        _createElementVNode("a", { href: "https://mekari.com/kebijakan-privasi/" }, "Privacy"),
        _createElementVNode("a", { href: "https://mekari.com/persyaratan-layanan/" }, "Terms of use"),
        _createElementVNode("span", null, "© 2021 PT Mid Solusi Nusantara")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_logo)
      ])
    ])
  ]))
}