import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "menu-center" }
const _hoisted_4 = { class: "menu-right" }
const _hoisted_5 = {
  href: "/users/sign_in",
  class: "sign-up"
}
const _hoisted_6 = { class: "menu-container" }
const _hoisted_7 = { class: "product" }
const _hoisted_8 = { class: "product-logo" }
const _hoisted_9 = {
  href: "/docs/kb/product-api",
  class: "product"
}
const _hoisted_10 = { class: "product-logo" }
const _hoisted_11 = {
  href: "/docs/kb/product-api",
  class: "product"
}
const _hoisted_12 = { class: "product-logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_mpx_icon = _resolveComponent("mpx-icon")!
  const _component_jurnal_logo = _resolveComponent("jurnal-logo")!
  const _component_talenta_logo = _resolveComponent("talenta-logo")!
  const _component_klikpajak_logo = _resolveComponent("klikpajak-logo")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(['header', _ctx.headerClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_logo)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: "/docs/kb",
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMenuContainer('api'))),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeMenuContainer('menu-center')))
        }, [
          _cache[4] || (_cache[4] = _createTextVNode(" APIs ")),
          _createVNode(_component_mpx_icon, { variant: 'chevron-down' })
        ], 32),
        _cache[5] || (_cache[5] = _createElementVNode("a", { href: "/docs/kb" }, "Documentation", -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t("layout.landing.sign_in")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: _normalizeClass(['arrow-border', `arrow-on-${_ctx.activeMenuBox}`])
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("span", { class: "arrow" }, null, -1)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(['box', _ctx.activeMenuBox]),
          onMouseover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.keepMenuContainer && _ctx.keepMenuContainer(...args))),
          onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeMenuContainer('menu-box')))
        }, [
          (_ctx.activeMenuBox === 'api')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_jurnal_logo)
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "product-description" }, [
                    _createElementVNode("h3", null, [
                      _createTextVNode("Jurnal "),
                      _createElementVNode("span", null, "(Coming Soon)")
                    ]),
                    _createElementVNode("p", null, " Online accounting software for finance and bookkeeping automation ")
                  ], -1))
                ]),
                _createElementVNode("a", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_talenta_logo)
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "product-description" }, [
                    _createElementVNode("h3", null, "Talenta"),
                    _createElementVNode("p", null, " Cloud-based software for end-to-end HR operations, payroll, attendance, and benefit ")
                  ], -1))
                ]),
                _createElementVNode("a", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_klikpajak_logo)
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "product-description" }, [
                    _createElementVNode("h3", null, "Klikpajak"),
                    _createElementVNode("p", null, " DJP-certified application service provider for tax management and compliance ")
                  ], -1))
                ])
              ], 64))
            : _createCommentVNode("", true)
        ], 34)
      ])
    ])
  ], 2))
}