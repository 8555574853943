import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { href: "/docs/kb" }
const _hoisted_3 = { class: "image" }
const _hoisted_4 = { class: "explore" }
const _hoisted_5 = { class: "products" }
const _hoisted_6 = {
  href: "#",
  class: "product-box"
}
const _hoisted_7 = { class: "logo" }
const _hoisted_8 = {
  href: "/docs/kb",
  class: "product-box"
}
const _hoisted_9 = { class: "logo" }
const _hoisted_10 = { class: "link" }
const _hoisted_11 = {
  href: "/docs/kb",
  class: "product-box"
}
const _hoisted_12 = { class: "logo" }
const _hoisted_13 = { class: "link" }
const _hoisted_14 = { class: "ready" }
const _hoisted_15 = { href: "/docs/kb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_landing_header = _resolveComponent("landing-header")!
  const _component_mpx_button = _resolveComponent("mpx-button")!
  const _component_hero = _resolveComponent("hero")!
  const _component_jurnal_logo = _resolveComponent("jurnal-logo")!
  const _component_talenta_logo = _resolveComponent("talenta-logo")!
  const _component_mpx_icon = _resolveComponent("mpx-icon")!
  const _component_klikpajak_logo = _resolveComponent("klikpajak-logo")!
  const _component_landing_footer = _resolveComponent("landing-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_landing_header),
    _createElementVNode("main", null, [
      _createElementVNode("section", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Leverage your business through Mekari Developer API", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "subtitle" }, " Build your application on top of Mekari Platform and connect your business process through powerful APIs from all Mekari products. ", -1)),
        _createElementVNode("p", null, [
          _createElementVNode("a", _hoisted_2, [
            _createVNode(_component_mpx_button, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Get Started")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_hero)
        ])
      ]),
      _createElementVNode("section", _hoisted_4, [
        _cache[12] || (_cache[12] = _createElementVNode("h2", null, "Explore the docs", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "subtitle" }, " Find the tools, frameworks, and resources you need to build apps. ", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_jurnal_logo)
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Jurnal", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "description" }, " Online accounting software for finance and bookkeeping automation ", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "link coming-soon" }, "Coming soon", -1))
          ]),
          _createElementVNode("a", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_talenta_logo)
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Talenta", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "description" }, " Cloud-based software for end-to-end HR operations, payroll, attendance, and benefit ", -1)),
            _createElementVNode("p", _hoisted_10, [
              _cache[6] || (_cache[6] = _createTextVNode(" Read the guide ")),
              _createVNode(_component_mpx_icon, {
                variant: 'arrow-right',
                "primary-color": 'primary-400'
              })
            ])
          ]),
          _createElementVNode("a", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_klikpajak_logo)
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("h3", null, "KlikPajak", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "description" }, " DJP-certified application service provider for tax management and compliance ", -1)),
            _createElementVNode("p", _hoisted_13, [
              _cache[9] || (_cache[9] = _createTextVNode(" Read the guide ")),
              _createVNode(_component_mpx_icon, {
                variant: 'arrow-right',
                "primary-color": 'primary-400'
              })
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_14, [
        _cache[15] || (_cache[15] = _createElementVNode("h2", null, "Ready to build?", -1)),
        _cache[16] || (_cache[16] = _createElementVNode("p", { class: "subtitle" }, "See the documentation to get started.", -1)),
        _createElementVNode("p", null, [
          _createElementVNode("a", _hoisted_15, [
            _createVNode(_component_mpx_button, null, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Explore the docs")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createVNode(_component_landing_footer)
  ], 64))
}